import React from "react";
import { useHistory } from "react-router-dom";

export default function Abholung() {
  const history = useHistory();
  function handleClick() {
    history.push(`/`);
  }
  return (
    <div className="centering textweiss">
      <div className="margin-top margin-rund">
        <div className="fweight bullets">
          <p className="gross">Abholung</p>

          <p>
            <span className="unterstrichen">
              Eine schnelle Lieferung spontan in der Stadt versenden ohne das
              Haus zu verlassen?
            </span>{" "}
            Kein Problem! Alles ganz bequem.
          </p>

          <p>
            <span className="unterstrichen">Lieferung von Tür zu Tür.</span> -
            Und das super schnell und günstig.
          </p>

          <div className="textcenter margin-top">
            {" "}
            <button onClick={handleClick}>Zurück</button>
          </div>
        </div>
      </div>
    </div>
  );
}
