import React, { useState } from "react";
import Beispiel from "../components/Beispiel";
import Faq from "../components/Faq";
import { useHistory } from "react-router-dom";
import ichliefere from "../ichliefere.png";
import lieferung from "../lieferung.png";
import abholung from "../abholung.png";
import vier from "../vier.png";

export default function Start() {
  const history = useHistory();
  function handleClick() {
    history.push("/impressum");
  }

  function clickAGB() {
    history.push("/agb");
  }

  function clickDS() {
    history.push("/datenschutz");
  }

  // function clickPreis() {
  //   history.push("/preise");
  // }

  // function clickAbhol() {
  //   history.push("/abgeholt");
  // }

  return (
    <div className="centering textweiss">
      <div className="margin-top margin-rund">
        <p className="gross fweightgross textcenter">
          Wir sind bald wieder Online...
        </p>
        <div className="textcenter">
          <img
            className="start-img"
            src={ichliefere}
            alt="Fahrer mit Paketen"
          />
        </div>
        {/* <div className="fweight bullets margin-kleintop">
          <ul>
            <li>
              <span>Bequem:</span> Abhol- und Lieferort in Mannheim bestimmen
              Sie
            </li>
            <li>
              <span>Schnell:</span> Zustellung am gleichen Tag
            </li>
            <li>
              <span>Günstig:</span> Pro Paket 4,50€
            </li>
            <li>
              <span>Unkompliziert:</span> Standardpreis bis 10 kg und max 60 x
              45 x 45 cm
            </li>
            <li>
              <span>Ökologisch:</span> Wann immer möglich, liefern wir mit dem
              Fahrrad oder Fahrradanhänger.
            </li>
            <li>
              <span>Flexibel:</span> Für regelmäßige oder große Lieferungen sind
              individuelle Vereinbarungen möglich.
            </li>
          </ul>
        </div>
        <div className="textcenter pointer" onClick={clickPreis}>
          {" "}
          <img
            className="start-img margin-top"
            src={vier}
            alt="Ab 4,50 Euro pro Paket"
          />
        </div>
        <p className="gross">
          Sie haben einen Lieferauftrag in Mannheim? Einfach anrufen und Termin
          zur Abholung vereinbaren!
        </p>
        <p className="gross textcenter">01575 93 611 01</p>
        <p className="bisschen textcenter">E-Mail: info@lieferbit.de</p>
        <div className="textcenter">
          {" "}
          <img
            className="start-img margin-top pointer"
            onClick={clickAbhol}
            src={abholung}
            alt="Abholung und Lieferung"
          />
        </div>
        <div className="margin-top">
          <Beispiel />
        </div>
        <div className="margin-top">
          <Faq />
        </div>
        <div className="textcenter">
          {" "}
          <img className="start-img" src={lieferung} alt="Paket angekommen" />
        </div> */}
        <p className="textcenter">
          <span
            className="impress textcenter pointer margin-agb texteins"
            onClick={handleClick}
          >
            Impressum
          </span>{" "}
          <span
            className="impress textcenter pointer margin-agb texteins"
            onClick={clickAGB}
          >
            AGB
          </span>{" "}
          <span
            className="impress textcenter pointer margin-agb texteins"
            onClick={clickDS}
          >
            Datenschutz
          </span>
        </p>{" "}
      </div>
    </div>
  );
}
