import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export default function Agb() {
  const history = useHistory();
  function handleClick() {
    history.push(`/`);
  }

  return (
    <Fragment>
      <div className="centering margin-rund">
        <div className="margin-rund">
          <p>Allgemeine Geschäftsbedingungen</p>
          <p>
            Die AGB werden aktuell angepasst und sind bald wieder abrufbar...
          </p>

          <div className="textcenter">
            {" "}
            <button onClick={handleClick}>Zurück</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
