import React from "react";
import { useHistory } from "react-router-dom";

export default function Preis() {
  const history = useHistory();
  function handleClick() {
    history.push(`/`);
  }
  return (
    <div className="centering textweiss">
      <div className="margin-top margin-rund">
        <div className="fweight bullets">
          <p className="gross">Preise</p>

          <p>
            <span className="unterstrichen">
              Der Standardpreis pro Paket beträgt 4,50 Euro inklusive
              Umsatzsteuer ( 3,78 Euro zzgl. Umsatzsteuer).{" "}
            </span>{" "}
            Dabei sind die Höchstmaße 60 cm x 45 cm x 45 cm pro Paket, und das
            Höchstgewicht 10 kg. Wenn Sie also schnell 2 Pakete vom Lindenhof in
            die Neckarstadt verschicken wollen, kostet dies nur 9.- Euro.
          </p>

          <p>
            <span className="unterstrichen">
              Sind Abweichungen vom Preis möglich?
            </span>{" "}
            Ja! Wenn Sie eine große Stückzahl an Paketen versenden, oder nur
            sehr kleine Pakete, kann individuell ein Rabatt vereinbart werden.
            Bei sperrigen oder schweren Paketen wird entsprechend ein Aufpreis
            berechnet.
          </p>
          <p>
            <span className="unterstrichen">
              Sind Lieferungen außerhalb Mannheims möglich?
            </span>{" "}
            Grundsätzlich Ja. Hier wird der Preis dann individuell nach Aufwand
            berechnet.
          </p>
          <p>
            <span className="unterstrichen">
              Sind Pakete über 10 kg oder besonders große Pakete möglich?
            </span>{" "}
            Grundsätzlich Ja. Auch hier wird der Preis dann individuell nach
            Aufwand berechnet.
          </p>
          <div className="textcenter margin-top">
            {" "}
            <button onClick={handleClick}>Zurück</button>
          </div>
        </div>
      </div>
    </div>
  );
}
