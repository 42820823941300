import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export default function Impressum() {
  const history = useHistory();
  function handleClick() {
    history.push(`/`);
  }

  return (
    <Fragment>
      <div className="centering margin-rund">
        <div className="margin-rund">
          <p>
            Das Spiel "Tillstetten" ist ein Produkt der Manachlux UG
            (haftungsbeschränkt)
          </p>{" "}
          <p>
            <a
              href="https://manachlux.com"
              target="manachlux.com"
              rel="noopener noreferrer"
            >
              www.manachlux.com
            </a>
          </p>
          <p>Besselstr. 25, 68219 Mannheim</p>
          <p>
            Eingetragen im Handelsregister - Registergericht: Mannheim,
            Registernummer HRB 733705
          </p>
          <p>Vertreten durch Geschäftsführer David Sheikh</p>{" "}
          <p>Umsatzsteuer-Identifikationsnummer: DE325044873</p>{" "}
          <p>Telefon: 0621 97688197</p>
          <p>Email: manachlux@gmail.com</p>
          <p>Verantwortlich für den Inhalt nach §55 Abs 2 RStV: David Sheikh</p>
          <p>
            Die Europäische Kommission stellt eine Online-Plattform zur
            Streitbeilegung bereit, die Sie unter{" "}
            <span>
              <a
                href="https://ec.europa.eu/consumers/odr"
                target="manachlux.com"
                rel="noopener noreferrer"
              >
                https://ec.europa.eu/consumers/odr
              </a>
            </span>{" "}
            finden. Wir sind zur Teilnahme an einem Streitbeilegungsverfahren
            vor einer Verbraucherschlichtungsstelle nicht verpflichtet und nicht
            bereit.
          </p>
          <div className="textcenter">
            {" "}
            <button onClick={handleClick}>Zurück</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
