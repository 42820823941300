import React, { Fragment } from "react";
import { useHistory } from "react-router-dom";

export default function Datensschutz() {
  const history = useHistory();
  function handleClick() {
    history.push(`/`);
  }

  return (
    <Fragment>
      <div className="centering margin-rund">
        <div className="margin-rund">
          <p>Datenschutz</p>
          <p>Speicherung persönlicher Daten</p>
          <p>
            Daten die Sie uns zur Verfügung stellen etwa wenn sie uns über
            Telefon oder E-Mail kontaktieren, werden sicher verwahrt, und nur
            für den unmittelbaren Zweck Ihrer Anfrage bearbeitet. Diese Daten
            werden nicht an Dritte weitergegeben (außer wir sind etwa bei
            Rechtsverstößen Behörden gegenüber zur Auskunft verpflichtet).
          </p>
          <p>
            Wir nutzen diese Daten zur Abwicklung der auf dieser Website
            angebotenen Dienstleistung.
          </p>
          <p>
            Wenn Sie die vollständige Löschung Ihrer Daten wünschen, reicht eine
            formlose E-Mail an info@lieferbit.de
          </p>
          <p>
            Grundsätzlich haben Sie folgende Rechte: Recht auf Berichtigung,
            Recht auf Löschung, Recht auf Einschränkung der Verarbeitung, Recht
            auf Benachrichtigung, Recht auf Datenübertragbarkeit,
            Widerspruchsrecht, und das Recht nicht von Profiling betroffen zu
            sein (Artikel 16 - 22 DSGVO).
          </p>

          <div className="textcenter">
            {" "}
            <button onClick={handleClick}>Zurück</button>
          </div>
        </div>
      </div>
    </Fragment>
  );
}
