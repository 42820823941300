import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import "./App.css";
import Main from "./pages/main";
import Impressum from "./pages/impressum";
import AGB from "./pages/agb";
import Datenschutz from "./pages/datenschutz";
import Abholung from "./pages/abholung";
import Preis from "./pages/preis";

function App() {
  return (
    <>
      <Router>
        <Switch>
          <Route exact path="/" component={Main} />
          <Route exact path="/impressum" component={Impressum} />
          <Route exact path="/datenschutz" component={Datenschutz} />
          <Route exact path="/agb" component={AGB} />
          <Route exact path="/preise" component={Preis} />
          <Route exact path="/abgeholt" component={Abholung} />
        </Switch>
      </Router>
    </>
  );
}

export default App;
